import React from 'react'

function Testing() {
    return (
        <div>
            this is a testing cos
        </div>
    )
}

export default Testing
